<template>
  <div class="search-box">
    <el-form :inline="true" :model="queryForm" class="demo-form-inline">
      <el-form-item label="部门">
        <el-select
            ref="queryFormTreeSelect"
            v-model="queryForm.selectDept"
            placeholder="请选择部门"
            fit-input-width="true"
            style="width: 100%"
            size="small"
            :disabled="disabledDeptSelect"
            clearable
            @clear="clearSelect"
        >
          <el-option style="height: auto; padding: 0">
            <el-tree
                expand-on-click-node="false"
                default-expand-all
                ref="tree"
                highlight-current="true"
                :data="deptTree"
                node-key="id"
                @node-click="queryFormChooseDept"
            />
          </el-option>
        </el-select>
      </el-form-item>
<!--      <el-form-item label="部门test">
      <treeselect v-model="value" :multiple="true" :options="deptTree" />
      </el-form-item>-->
      <el-form-item label="用户名">
        <el-input
            v-model="queryForm.username"
            placeholder=""
            size="small"
            ref="usernameInput"
        ></el-input>
      </el-form-item>
      <el-form-item label="姓名">
        <el-input
            v-model="queryForm.name"
            placeholder=""
            size="small"
        ></el-input>
      </el-form-item>
      <el-form-item>
        <el-button icon="el-icon-search" type="primary" @click="submit" size="small">查询</el-button>
        <el-button icon="el-icon-plus" @click="addInfo" size="small" v-show="hasAddPermision"
        >新增用户
        </el-button
        >
      </el-form-item>
    </el-form>
  </div>
  <div class="data-box">
    <el-table
        v-loading="loading"
        element-loading-text="加载中..."
        element-loading-spinner="el-icon-loading"
        element-loading-background="rgba(0, 0, 0, 0.8)"
        :data="tableData"
        style="width: 100%"
    >
      <el-table-column prop="username" label="用户名"/>
      <el-table-column prop="name" label="姓名"/>
      <el-table-column prop="deptName" label="部门"/>
      <el-table-column prop="createTime" label="创建时间"/>
      <el-table-column prop="lastLoginTime" label="最后一次登录"/>
      <el-table-column prop="status" label="状态">
        <template #default="scope">
          <el-tag
              :type="scope.row.status === 0 ? 'info' : 'success'"
              disable-transitions
          >{{ scope.row.status == 0 ? "禁用" : "正常" }}
          </el-tag
          >
        </template>
      </el-table-column>

      <el-table-column label="操作" fixed="right" width="180" align="center" v-if="hasDeletePermision||hasEditPermision">
        <template #default="scope">
          <el-button icon="el-icon-edit" size="mini" @click="handleEdit(scope.$index, scope.row)" v-show="hasEditPermision"
          >编辑
          </el-button
          >
          <el-popconfirm
              confirm-button-text="确定"
              cancel-button-text="取消"
              :title="'确定删除用户['+scope.row.name+']吗?'"
              @confirm="handleDelete(scope.$index, scope.row)"
          >
            <template #reference>
              <el-button icon="el-icon-delete" size="mini" type="danger" v-show="hasDeletePermision">删除</el-button>
            </template>
          </el-popconfirm>
        </template>
      </el-table-column>
    </el-table>
  </div>
  <div class="page-box">
    <el-pagination
        v-model:currentPage="curPage"
        :page-sizes="[15, 50, 100, 200]"
        :page-size="pageSize"
        layout="total, sizes, prev, pager, next, jumper"
        :total="total"
        background="true"
        @size-change="handleSizeChange"
        @current-change="handleCurrentChange"
    >
    </el-pagination>
  </div>

  <!--用户信息新增、修改弹窗-->
  <el-dialog
      v-model="dialogVisible"
      :title="dialogTitle"
      width="30%"
      @close="handleClose"
  >
    <el-form
        ref="ruleForm"
        :model="ruleForm"
        :rules="rules"
        label-width="80px"
        class="demo-ruleForm"
    >
      <el-form-item label="账号" prop="username" class="label-color">
        <el-input
            v-model="ruleForm.username"
            placeholder="请输入用户名"
            clearable
            :readonly="usernameReadOnly"
        ></el-input>
      </el-form-item>
      <el-form-item label="姓名" prop="name" class="label-color">
        <el-input
            v-model="ruleForm.name"
            placeholder="请输入姓名"
            clearable
        ></el-input>
      </el-form-item>
      <el-form-item label="密码" prop="password" class="label-color">
        <el-input
            v-model="ruleForm.password"
            type="password"
            :placeholder="passwordPlaceholder"
            clearable
            show-password
        ></el-input>
      </el-form-item>
      <el-form-item label="部门" prop="deptName" class="label-color">
        <el-select
            ref="ruleFormTreeSelect"
            v-model="ruleForm.deptName"
            placeholder="请选择部门"
            fit-input-width="true"
            style="width: 100%"
            :disabled="disabledDeptSelect"
            clearable
            @clear="clearDept"
        >
          <el-option style="height: auto; padding: 0">
            <el-tree
                expand-on-click-node="false"
                default-expand-all
                ref="tree"
                highlight-current="true"
                :data="deptTree"
                node-key="id"
                :props="{ class: customNodeClass }"
                @node-click="handleNodeClick"
            />
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="角色" prop="role" class="label-color">
        <el-checkbox-group v-model="ruleForm.roleIds">
          <el-checkbox :key="role.id" v-for="role in roleList" :label="role.id" >{{role.roleName}}</el-checkbox>
        </el-checkbox-group>
      </el-form-item>
    </el-form>
    <template #footer>
      <span class="dialog-footer">
        <el-button @click="dialogVisible = false">取消</el-button>
        <el-button type="primary" @click="saveUserInfo" v-if="isSave == true"
        >保存</el-button
        >
        <el-button type="primary" @click="editUserInfo" v-if="isSave == false"
        >修改</el-button
        >
      </span>
    </template>
  </el-dialog>
</template>

<script>
import userApi from "@/api/system/user";
import deptApi from "@/api/system/dept";
import {handleTree} from "@/utils/treeUtil";
import roleApi from "@/api/system/role";
import {ref} from "vue";

export default {
  data() {
    return {
      hasAddPermision:false,
      hasEditPermision:false,
      hasDeletePermision:false,
      disabledDeptSelect: false,
      roleList:[],
      deptTree: [],
      queryForm: {
        selectDept: "",
        deptId: "",
        username: "",
        name: "",
      },
      loading: false,
      total: 0,
      pageSize: 15,
      curPage: 1,
      tableData: [],
      passwordPlaceholder: "请输入密码",
      isSave: false,
      usernameReadOnly: false,
      ruleForm: {
        id: "",
        username: "",
        name: "",
        password: "",
        deptId: "",
        deptName: "",
        role: [],
      },
      rules: {
        username: [
          {
            required: true,
            message: "请输入用户名",
            trigger: "blur",
          },
        ],
        name: [
          {
            required: true,
            message: "请输入姓名",
            trigger: "blur",
          },
        ],
        password: [
          {
            required: true,
            message: "请输入密码",
            trigger: "blur",
          },
        ],
        /*deptName: [
          {
            required: true,
            message: "请选择部门",
            trigger: "blur",
          },
        ],*/
      },
      dialogTitle: "",
      dialogVisible: false,
    };
  },
  mounted() {
    this.$nextTick(()=> {
      // 获取设置按钮权限
      this.hasAddPermision = this.$menuUtil.hasMenu('/system/userManage:add');
      this.hasEditPermision = this.$menuUtil.hasMenu('/system/userManage:edit');
      this.hasDeletePermision = this.$menuUtil.hasMenu('/system/userManage:delete');
    })
    this.queryDeptTree();
    this.queryUser();
    this.queryAllRole();
  },
  methods: {
    // 清空弹窗的部门选择
    clearDept(){
      this.ruleForm.deptName = '';
      this.ruleForm.deptId = null;
    },
    clearSelect(){
      this.queryForm.selectDept = '';
      this.queryForm.deptId = '';
      this.queryUser();
    },
    queryAllRole(){
      roleApi.queryAllRole().then(response=>{
        this.roleList=response.data.data;
      });
    },
    queryDeptTree() {
      deptApi
          .queryDept({
            deptName: "",
          })
          .then((response) => {
            this.deptTree = handleTree(response.data.data, "deptName");
          });
    },
    customNodeClass(data, node) {
      console.log(node);
      if (data.isPenultimate) {
        return "is-penultimate";
      }
      return "";
    },
    queryFormChooseDept(data) {
      this.queryForm.selectDept = data.label;
      this.queryForm.deptId = data.id;
      this.$refs.queryFormTreeSelect.blur();// 让select失去焦点，收起下拉
    },
    handleNodeClick(data) {
      this.ruleForm.deptName = data.label;
      this.ruleForm.deptId = data.id;
      this.$refs.ruleFormTreeSelect.blur();// 让select失去焦点，收起下拉
    },
    submit() {
      this.curPage = 1;
      this.queryUser();
    },
    handleSizeChange(val) {
      this.pageSize = val;
      this.queryUser();
    },
    handleCurrentChange(val) {
      this.curPage = val;
      this.queryUser();
    },
    queryUser() {
      userApi
          .queryUserPage({
            username: this.queryForm.username,
            name: this.queryForm.name,
            deptId: this.queryForm.deptId,
            pageSize: this.pageSize,
            curPage: this.curPage,
          })
          .then((response) => {
            this.tableData = response.data.data.records;
            this.total = response.data.data.total;
          });
    },
    handleEdit(index, row) {
      console.log(index, row);
      this.usernameReadOnly = true;
      this.isSave = false;
      this.dialogTitle = "编辑用户信息";
      this.dialogVisible = true;
      this.ruleForm.id = row.id;
      this.ruleForm.username = row.username;
      this.ruleForm.name = row.name;
      this.ruleForm.deptId = row.deptId;
      this.ruleForm.deptName = row.deptName;
      this.ruleForm.password = "";
      this.rules.password = "";
      this.passwordPlaceholder = "留空则不修改";
      // 选中树中值，这里要加$nextTick，否则不生效
      this.$nextTick(() => {
        this.$refs.tree.setCurrentKey(row.deptId);
      });
      // 根据角色id查询菜单id
      this.ruleForm.roleIds = [];// 不初始化会报错
      userApi.queryUserRole(row.id).then(response=>{
        if(response.data.code==1){
          this.ruleForm.roleIds = response.data.data.roleIds;
        }
      });
    },
    addInfo() {
      this.usernameReadOnly = false;
      this.isSave = true;
      this.dialogTitle = "新增用户";
      this.dialogVisible = true;
      this.passwordPlaceholder = "请输入密码";
      this.rules.password.push({
        required: true,
        message: "请输入密码",
        trigger: "blur",
      });
      // 重置表单
      // this.$refs['ruleForm'].resetFields();
      // 记录当前值，当非系统管理员时，默认选中所属部门，并禁用
      var deptName = this.ruleForm.deptName;
      var deptId = this.ruleForm.deptId;
      this.ruleForm = {};
      this.ruleForm.roleIds = ref([]);
      this.ruleForm.deptId = "";
      this.$refs.tree.setCheckedKeys([]);
      if (this.disabledDeptSelect) {
        this.ruleForm.deptName = deptName;
        this.ruleForm.deptId = deptId;
      }
    },
    handleDelete(index, row) {
      userApi.delUser(row.id).then((response) => {
        if (response.data.code == 1) {
          this.$notify({
            title: response.data.msg,
            type: "success",
            offset: 40,
            duration: 2000,
          });
          this.tableData.splice(index, 1);
        } else {
          this.$notify({
            title: response.data.msg,
            type: "warning",
            offset: 40,
            duration: 3000,
          });
        }
      });
    },
    saveUserInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          // 调用接口保存
          userApi.addUser(this.ruleForm).then((response) => {
            if (response.data.code == 1) {
              this.$notify({
                title: response.data.msg,
                type: "success",
                offset: 40,
                duration: 2000,
              });
              // 关闭窗口
              this.dialogVisible = false;
              // 重置表单
              // this.$refs['ruleForm'].resetFields();
              // 刷新列表
              this.queryUser();
            } else {
              this.$notify({
                title: "保存失败：" + response.data.msg,
                type: "warning",
                offset: 40,
                duration: 2000,
              });

            }
          });
        }
      });
    },
    editUserInfo() {
      this.$refs["ruleForm"].validate((valid) => {
        if (valid) {
          console.log('this.role',this.ruleForm.roleIds)
          // 调用接口保存
          userApi.editUser(this.ruleForm).then((response) => {
            if (response.data.code == 1) {
              this.$notify({
                title: response.data.msg,
                type: "success",
                offset: 40,
                duration: 2000,
              });
              // 关闭窗口
              this.dialogVisible = false;
              // 重置表单
              // this.$refs['ruleForm'].resetFields();
              // 刷新列表
              this.queryUser();
            } else {
              this.$notify({
                title: "修改失败：" + response.data.msg,
                type: "warning",
                offset: 40,
                duration: 2000,
              });

            }
          });
        }
      });
    },
    // 弹窗关闭事件
    handleClose() {
      this.ruleForm = {};
      this.ruleForm.roleIds = [];
    },
  },
};
</script>

<style scoped>

.is-penultimate > .el-tree-node__content {
  color: red;
}
</style>